export default {
  methods: {
    numberToWords(number) {
      let string = number.toString()
      let start
      let end
      let chunk
      let ints
      let i
      let word
      const and = 'and'

      /* Remove spaces and commas */
      string = string.replace(/[, ]/g, '')

      /* Is number zero? */
      if (parseInt(string, 10) === 0) {
        return 'zero'
      }

      /* Array of units as words */
      const units = [
        '',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
      ]

      /* Array of tens as words */
      const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

      /* Array of scales as words */
      const scales = [
        '',
        'thousand',
        'million',
        'billion',
        'trillion',
        'quadrillion',
        'quintillion',
        'sextillion',
        'septillion',
        'octillion',
        'nonillion',
        'decillion',
        'undecillion',
        'duodecillion',
        'tredecillion',
        'quatttuor-decillion',
        'quindecillion',
        'sexdecillion',
        'septen-decillion',
        'octodecillion',
        'novemdecillion',
        'vigintillion',
        'centillion',
      ]

      /* Split user argument into 3 digit chunks from right to left */
      start = string.length
      const chunks = []
      while (start > 0) {
        end = start
        chunks.push(string.slice((start = Math.max(0, start - 3)), end))
      }

      /* Check if function has enough scale words to be able to stringify the user argument */
      const chunksLen = chunks.length
      if (chunksLen > scales.length) {
        return ''
      }

      /* Stringify each integer in each chunk */
      const words = []
      for (i = 0; i < chunksLen; i++) {
        chunk = parseInt(chunks[i], 10)

        if (chunk) {
          /* Split chunk into array of individual integers */
          ints = chunks[i]
            .split('')
            .reverse()
            .map(parseFloat)

          /* If tens integer is 1, i.e. 10, then add 10 to units integer */
          if (ints[1] === 1) {
            ints[0] += 10
          }

          /* Add scale word if chunk is not zero and array item exists */
          if ((word = scales[i])) {
            words.push(word)
          }

          /* Add unit word if array item exists */
          if ((word = units[ints[0]])) {
            words.push(word)
          }

          /* Add tens word if array item exists */
          if ((word = tens[ints[1]])) {
            words.push(word)
          }

          /* Add 'and' string after units or tens integer if: */
          if ((ints[0] || (ints[1])) && number > 100) {
            /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
            if (ints[2] || (!i && chunksLen)) {
              words.push(and)
            }
          }

          /* Add hundreds word if array item exists */
          if ((word = units[ints[2]])) {
            words.push(`${word} hundred`)
          }
        }
      }

      return this.capitalizeFirstLetter(`${words.reverse().join(' ')} shillings only`)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}

<template>
  <div class="transactions">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <transaction-table :transaction-url="transactionUrl" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import TransactionTable from '@/components/tables/TransactionTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    'transaction-table': TransactionTable,
  },
  data() {
    return {
      isTransactionsLoading: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Transactions',
          disabled: true,
          to: { name: 'transactions' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    transactionUrl() {
      return `transactions?station_id=${this.selectedStation}`
    },
  },
}
</script>

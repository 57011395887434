<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Transfer transaction</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="transferTransaction()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7">
                  <v-text-field
                    v-model="transactionReference"
                    label="Transaction reference"
                    placeholder="QHB228Z42Q"
                    disabled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    placeholder="1000"
                    disabled
                    readonly
                    :rules="[v => !!v || 'required', v => v > 0 || 'The value must be greater than 0']"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    :value="fromAccount"
                    label="From account"
                    placeholder="Account number"
                    disabled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="8"
                  class="mb-2"
                >
                  <v-autocomplete
                    v-model="form.to_account_number"
                    :items="users"
                    :loading="isUsersLoading"
                    :search-input.sync="users_search"
                    :filter="filterObject"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="account_number"
                    cache-items
                    hide-no-data
                    label="To account"
                    placeholder="Search customer name or account number"
                    :rules="[v => !!v || 'Customer name is required']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for customer
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      {{ data.item.name }} - {{ data.item.account_number }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar class="primary font-weight-light white--text">
                        {{ data.item.name.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.account_number">
                        </v-list-item-subtitle>
                        <br />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <small
                    v-show="form.errors.has('to_account_number')"
                    class="validation-error"
                  >{{
                    form.errors.get('to_account_number')
                  }}</small>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="form.account_type"
                    :items="account_types"
                    item-text="name"
                    item-value="id"
                    label="Account type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    transactionId: {
      type: String,
      default: null,
    },
    amount: {
      type: Number,
      default: 0,
    },
    accountNumber: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    transactionReference: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    form: new Form({
      transaction_id: '',
      to_account_number: '',
      from_account_number: '',
      account_type: 1,
    }),
    users: [],
    isUsersLoading: false,
    action: 'Transfer',
    users_search: null,
    account_types: [
      {
        id: 1,
        name: 'Main',
      },
      {
        id: 2,
        name: 'Connection Fee',
      },
    ],
  }),
  computed: {
    fromAccount() {
      return `${this.name} - ${this.accountNumber}`
    },
  },
  watch: {
    users_search(value) {
      this.getUsers(value)
    },
    showDialog(value) {
      if (value && this.users.length === 0) {
        this.getUsers()
      }
    },
    transactionId(value) {
      this.form.transaction_id = value
    },
    accountNumber(value) {
      this.form.from_account_number = value
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || item.account_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    closeDialog() {
      this.$emit('close')
    },
    getUsers: _.debounce(function (value) {
      this.isUsersLoading = true
      axios
        .get(`users?searchByNameAndAccountNo=${value}`)
        .then(response => {
          this.users = response.data.data
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    transferTransaction() {
      this.form
        .post('transfer-transaction')
        .then(() => {
          this.$notification.success(
            `Transaction of Ksh ${this.amount} has been successfully transferred from account ${this.accountNumber} to ${this.form.to_account_number}`,
          )
          this.$emit('close')
          this.$emit('transaction-transferred')
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
